import styled from 'styled-components';
import React, { useState, useRef, useCallback } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Trail, animated } from 'react-spring';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { PRIMARY_BG_COLOR, PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';

const CardContainer = styled(Container)`
    background-color: ${props => props.color} !important;

    p {
        font-size: 18px !important;
    }

    @media (max-width: 768px) {
        margin-right: 5vw !important;
        margin-left: 5vw !important;
        width: 90vw !important;

        .m-space {
            margin-top: 60px;
        }
    }

    .space {
        margin-top: 60px;
    }

    .client {
        margin-right: 0;
        color: white;
        background-color: #2f364a;
    }
`;

const ChatBubble = styled.div`
    border-radius: 20px;
    background-color: white;
    color: #363636;
    padding: 15px 25px;
    position: relative;
    display: inline-block;
    word-break: break-word;
    flex-grow: 1;
    width: 80%;
    margin-top: 20px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
`;

const ChatBubbleCol = styled(Col)`
    border-radius: 15px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
    background-color: ${props => props.bgColor};
    color: ${props => props.txtColor};
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 20%;
    flex-grow: 0;

    padding-bottom: 20px !important;
    padding-top: 20px !important;

    h1 {
        color: ${props => props.txtColor} !important;
        font-family: ${TITLE_FONT};
        font-size: 26px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -1.3px;
        text-align: center;
    }
`;

const ScrollingRow = styled(Row)`    
    .client {
        background-color: #b6d6f2;
        color: ${PRIMARY_TEXT_COLOR}
        max-width: 50%;
        width: auto;
    }
`;

const StyledForm = styled(Form)`
    text-align: left;
    width: 100%;

    button {
        margin-top: 30px;
    }

    .form-group {
        label {
            text-transform: uppercase;
            font-size: 12px;
        }
        margin-bottom: 30px;
    }

    .form-check {
        label {
            font-size: 12px;
            line-height: 24px;
        }
    }
`;

class ChatItem {
    constructor(text, responses, client = true, minWorth = null, maxWorth = null) {
        this.client = client;
        this.text = text;
        this.responses = responses;
        this.minWorth = minWorth;
        this.maxWroth = maxWorth;
    }

    toString() {
        return this.client + this.text + this.responses + this.minWorth + this.maxWroth;
    }
}

const START = new ChatItem(
    'First we need to know at what stage you are.',
    [
        new ChatItem('I have an Idea.', [
            new ChatItem(
                "That's amazing! Want us to design it?",
                [
                    new ChatItem(
                        'Yes!',
                        [
                            new ChatItem(
                                'Ok what about strategy? Do you have it?',
                                [new ChatItem('No :(', []), new ChatItem('Yes!', [])],
                                false,
                            ),
                        ],
                        true,
                        100,
                        500,
                    ),
                    new ChatItem('No', []),
                ],
                false,
            ),
        ]),
        new ChatItem('I have some designs.', []),
        new ChatItem('I have a high fidelity prototype.', []),
    ],
    false,
);

const END = new ChatItem("That's it! We are done", [], false);

export const Chat = () => {
    const [choices, setChoices] = useState(START.responses);
    const [form] = useState(true);
    const [estimate, setEstimate] = useState(null);
    const [selectedChoices, setSelectedChoices] = useState([START]);
    const chatCol = useRef(null);
    const selectChoice = useCallback(
        choice => {
            const response = choice.responses ? (choice.responses[0] ? choice.responses[0] : END) : END;

            setChoices(response.responses);
            setSelectedChoices([...selectedChoices, choice, response]);
            if (choice.maxWroth && choice.maxWroth) {
                if (estimate) {
                    setEstimate([estimate[0] + choice.minWorth, estimate[1] + choice.maxWroth]);
                } else {
                    setEstimate([choice.minWorth, choice.maxWroth]);
                }
            }
        },
        [selectedChoices, estimate],
    );

    const items = [
        <Row key={'title'}>
            <ChatBubbleCol xl={{ offset: 2, size: 8 }} bgColor={'white'} txtColor={PRIMARY_BG_COLOR}>
                <h1 className={'mb-1'}>Let’s start our conversation.</h1>
                Our bot will help you to get started with us.
            </ChatBubbleCol>
        </Row>,
        <ScrollingRow key={'chat'} ref={chatCol} className={'d-block'}>
            {form ? (
                <ChatBubbleCol xl={{ offset: 2, size: 8 }} bgColor={'white'} txtColor={PRIMARY_BG_COLOR}>
                    <StyledForm>
                        <FormGroup>
                            <Label for="fullName">Full Name</Label>
                            <Input type="text" name="fullName" id="fullName" placeholder="Dobroslawa Antokolskiy" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">E-mail address</Label>
                            <Input type="email" name="email" id="email" placeholder="Antokolskiy@gmail.com" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="company">Company Name</Label>
                            <Input type="text" name="company" id="company" placeholder="Antokolskiy Ltd." />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" /> I agree with the privacy policy and term of conditions.
                            </Label>
                        </FormGroup>
                        <Button disabled={form}>Submit</Button>
                    </StyledForm>
                </ChatBubbleCol>
            ) : (
                selectedChoices.map((choice, index) => {
                    return (
                        <Col xl={{ offset: 2, size: 8 }}>
                            <ChatBubble className={choice.client ? 'client' : null}>{choice.text}</ChatBubble>
                        </Col>
                    );
                })
            )}
        </ScrollingRow>,
        <Row key={'choices'}>
            {form ? null : (
                <Col xl={{ offset: 2, size: 8 }}>
                    <Row>
                        {choices.map((choice, index) => {
                            return (
                                <ChatBubbleCol className={'option'} key={index} onClick={() => selectChoice(choice)}>
                                    {choice.text}
                                </ChatBubbleCol>
                            );
                        })}
                    </Row>
                </Col>
            )}
        </Row>,
    ];

    return (
        <>
            <CardContainer color={'#f7f7f7'}>
                <Trail
                    items={items}
                    keys={item => item.key}
                    from={{ transform: 'translate3d(0,400px,0)' }}
                    to={{ transform: 'translate3d(0,0px,0)' }}
                >
                    {item => props => <animated.div style={props}>{item}</animated.div>}
                </Trail>
            </CardContainer>
        </>
    );
};
