import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PRIMARY_BG_COLOR } from '../../constants';

export const Fullscreen = styled.div`
    width: 100%;
    height: ${props => (props.height ? props.height : '100%')};
    background-color: ${props => props.color};

    @media (max-width: 768px) {
        height: ${props => props.mobileHeight};
    }
`;

Fullscreen.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
};

Fullscreen.defaultProps = {
    color: PRIMARY_BG_COLOR,
};
